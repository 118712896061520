<template>
  <v-dialog max-width="80%" v-model="dialog" persistent>
    <v-tooltip right slot="activator">
      <v-btn slot="activator" flat icon color="primary">
        <v-icon>add</v-icon>
      </v-btn>
      <span>Create Plan</span>
    </v-tooltip>
    <div @dragover="followCursor" >
      <v-toolbar dense flat light color="primary">
        <v-toolbar-title>New Plan</v-toolbar-title>
      </v-toolbar>
      <v-stepper v-model="step" alt-labels>
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1" :editable="step > 1"
            >Location</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2" :editable="step > 2"
            >Facility</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 3" step="3" :editable="step > 3"
            >Sensors</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step step="4">Effectors</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-layout row wrap class="pa-3">
            <v-flex xs4 style="height: 55vh; overflow-y: scroll">
              <v-stepper-content step="1">
                <plan-step-1
                  v-if="dialog"
                  :active="dialog && step == 1"
                  ref="planStep1"
                  @next="nextStepReady = true"
                  @stop="nextStepReady = false"
                  @setPlanName="setPlanName"
                />
              </v-stepper-content>
              <v-stepper-content step="2">
                <plan-step-2
                  v-if="dialog"
                  :active="step == 2"
                  ref="planStep2"
                  @next="nextStepReady = true"
                  @setPlanType="setPlanType"
                />
              </v-stepper-content>
              <v-stepper-content step="3">
                <plan-step-3
                  v-if="dialog"
                  :active="step == 3"
                  ref="planStep3"
                  :plan="plan"
                  @setCNType="setCNType"
                  @next="nextStepReady = true"
                />
              </v-stepper-content>
              <v-stepper-content step="4">
                <plan-step-4
                  v-if="dialog"
                  :active="step == 4"
                  ref="planStep4"
                  :plan="plan"
                  @setCNType="setCNType"
                  @next="nextStepReady = true"
                />
              </v-stepper-content>
            </v-flex>
            <v-flex xs8 class="pl-3">
              <plan-map
                v-if="dialog"
                style="height: 55vh"
                :center="siteCenter"
                :zoom="siteZoom"
                :name="name"
                :searchBar="false"
                :plan="plan"
                @update:center="siteCenter = [$event.lat, $event.lng]"
                @update:latLng="updateInstallation"
                @update:zoom="siteZoom = $event"
                @setPlanSensor="setPlanSensor"
                draggableSensors
                :measureEnabled="false"
                class="wizardMap"
                :active="dialog"
              />
            </v-flex>
          </v-layout>
          <v-flex class="ma-3">
            <v-btn
              :disabled="!nextStepReady"
              color="primary"
              outline
              @click="nextStep"
              :loading="status === 'LOADING'"
              >{{ step == 4 ? 'Create Plan' : 'Continue' }}</v-btn
            >
            <v-btn flat @click="cancelDialog" color="primary">Cancel</v-btn>
          </v-flex>
        </v-stepper-items>
      </v-stepper>
    </div>
    <img
      id="dragging-ghost-icon"
      ref="draggingGhostIcon"
      :src="require('@/assets/sentry/green.png')"
    />
    <img
      id="pixel"
      src="data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="
      width="1"
      height="1"
    />
  </v-dialog>
</template>
<script>
import { install, mapActions, mapGetters, mapState } from 'vuex'
import { DialogMixin } from '@/components/Mixins'
import {
  PlanStep1,
  PlanStep2,
  PlanStep3,
  PlanStep4
} from '@/components/Widgets/Plan/Wizard'
import PlanMap from '@/components/Map/PlanMap'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import throttle from 'lodash/throttle'

export default {
  name: 'AddPlanDialog',
  mixins: [DialogMixin],
  components: { PlanMap, PlanStep1, PlanStep2, PlanStep3, PlanStep4 },
  data: () => ({
    dialog: false,
    step: 1,
    nbSteps: null,
    name: 'wizardMap',
    siteCenter: [20, 15],
    siteZoom: 2,
    nextStepReady: false,
    plan: {},
    installationNames: [
      'ALPHA',
      'BRAVO',
      'CHARLIE',
      'DELTA',
      'ECHO',
      'FOXTROT',
      'GOLF',
      'HOTEL',
      'INDIA',
      'JULIETT',
      'KILO',
      'LIMA',
      'MIKE',
      'NOVEMBER',
      'OSCAR',
      'PAPA',
      'QUEBEC',
      'ROMEO',
      'SIERRA',
      'TANGO',
      'UNIFORM',
      'VICTOR',
      'WHISKEY',
      'XRAY',
      'YANKEE',
      'ZULU'
    ],
    offsetSpot: false
  }),
  methods: {
    ...mapActions('plans', {
      createPlan: 'CREATE_PLAN',
      activateInstallation: 'ACTIVATE_INSTALLATION',
      toggleWizard: 'TOGGLE_PLANNING_WIZARD'
    }),
    initDialog() {
      this.activateInstallation(null)
      this.step = 1
      this.plan = {}
    },
    async nextStep() {
      this.step = parseInt(this.step) + 1
      this.nextStepReady = false
      if (this.step > this.nbSteps) {
        this.step -= 1
        await this.createPlan(this.plan)
        this.$emitter.emit('centerPlanMap', {coordinates: this.plan.site_plan.coordinates, zoom: this.plan.site_plan.zoom})
        this.cancelDialog()
      }
    },
    cancelDialog() {
      setTimeout(() => {
        this.$bus.$emit('mapCenter', this.name, [20, 15], 2, false)
      }, 200)
      this.dialog = false
      this.step = 1
      this.activateInstallation(null)
      this.$store.dispatch('maps/CLEAR_SEARCH_MAP_RESULT')
    },
    setPlanName(name) {
      this.plan = Object.assign({}, this.plan, {
        name,
        id: 'new',
        site_plan: {
          coordinates: this.siteCenter,
          zoom: this.siteZoom,
          installations: get(this.plan, 'site_plan.installations', []),
          facility_type: null,
          computenode_type: 'prem'
        }
      })
    },
    setPlanType(facility_type) {
      this.plan = Object.assign({}, this.plan, {
        site_plan: { ...this.plan.site_plan, facility_type }
      })
    },
    setCNType(computenode_type) {
      this.plan = Object.assign({}, this.plan, {
        site_plan: { ...this.plan.site_plan, computenode_type }
      })
    },
    setPlanSensor(data) {
      let installations = get(this.plan, 'site_plan.installations', [])
      const lastSentryId = get(installations.slice(-1)[0], 'id')
      const newSentryId = lastSentryId === undefined ? 0 : lastSentryId + 1
      const sentryType = data.sensorId.includes('dsx') ? 'vehicle' : 'fixed' //for dsx vehicle icon
      const usedNames = installations.map(i => i.name)
      const newSentryName =
        this.installationNames.find(name => !usedNames.includes(name)) ||
        'DroneSentry'

      if (data.sentryId == null) {
        installations.push({
          id: newSentryId,
          name: newSentryName,
          sentry_type: sentryType,
          offset: [60, -26],
          coordinates: [data.lat, data.lng]
        })
        this.plan = Object.assign({}, this.plan, {
          ...this.plan,
          site_plan: { ...this.plan.site_plan, installations }
        })
      }
      this.addSensor(
        data.sensorId,
        data.sentryId == null ? newSentryId : parseInt(data.sentryId),
        data.sensorName
      )
    },
    updateInstallation(e) {
      const newPlan = cloneDeep(this.plan)
      let installations = get(newPlan, 'site_plan.installations', [])
      const installationId = installations.findIndex(
        i => i.id == this.activeSentryId
      )
      installations[installationId].coordinates = [e.lat, e.lng]
      Object.keys(installations[installationId].sensors).forEach(k => {
        for (let i in installations[installationId].sensors[k]) {
          installations[installationId].sensors[k][i].latitude = e.lat
          installations[installationId].sensors[k][i].longitude = e.lng
        }
      })
      newPlan.site_plan.installations = installations
      this.plan = { ...newPlan }
    },
    addSensor(sensorId, installationId, sensorName) {
      const newPlan = cloneDeep(this.plan)
      let installations = get(newPlan, 'site_plan.installations', [])
      const installation = installations.find(i => i.id == installationId)
      newPlan.site_plan.installations = newPlan.site_plan.installations.filter(
        i => i.id != installationId
      )
      const sensors = installation.sensors || {}

      let sensorType = ''
      switch (sensorId) {
        case 'rf_zero':
        case 'rf_two':
        case 'rf_patrol':
          sensorType = 'rf_sensors'
          break
        case 'radar_zero':
          sensorType = 'radars'
          break
        case 'dsx_direct_no_jam':
        case 'dsx_direct':
          sensorType = 'dsx_sensors'
          break
        case 'droneopt1':
          sensorType = 'cameras'
          break
        case 'cannon':
          sensorType = 'cannons'
          break
        default:
          sensorType = ''
      }

      const sensorsList = get(installation, `sensors.${sensorType}`, [])
      const nbSensors =
        sensorId === 'rf_zero' ||
        sensorId === 'rf_two' ||
        sensorId === 'rf_patrol'
          ? sensorsList.filter(s => s.model === sensorId).length
          : sensorsList.length
      const lastSensorId = get(sensorsList.slice(-1)[0], 'id', -1)

      let direction
      const directionalSensors = ['radar_zero', 'rf_two', 'cannon']
      const directionalSensorTypes = ['radars', 'rf_sensors', 'cannons']
      if (nbSensors === 0) {
        direction = 0
        if (directionalSensors.includes(sensorId)) {
          directionalSensorTypes.splice(
            directionalSensors.findIndex(s => s === sensorId),
            1
          )
          direction =
            get(
              installation,
              `sensors.${directionalSensorTypes[0]}[0].direction`,
              0
            ) ||
            get(
              installation,
              `sensors.${directionalSensorTypes[1]}[0].direction`,
              0
            )
        }
      } else if (
        sensorId !== 'rf_zero' &&
        sensorId !== 'droneopt1' &&
        nbSensors <= 3
      ) {
        const emptySectors = this.availableSpots(sensorsList)
        if (!emptySectors.length) return
        direction = (sensorsList[0].direction + emptySectors[0] * 90) % 360
      } else {
        return
      }

      const sensor = {
        name: sensorName || sensorId,
        direction,
        type: sensorType,
        sentry_id: installationId,
        latitude: installation.coordinates[0],
        longitude: installation.coordinates[1],
        id: lastSensorId + 1
      }

      if (sensorType === 'rf_sensors') {
        sensor.reach = sensorId === 'rf_two' ? 8000 : 4000
        sensor.model = sensorId
      } else if (sensorType === 'dsx_sensors') {
        sensor.reach = 2000
        sensor.model = sensorId
        sensor.reach_jamming = 800
      } else if (sensorType === 'radars') {
        sensor.radar_type = 'radar_zero'
        sensor.mode = 'fixed'
        sensor.radar_az_max_search = 45
        sensor.radar_az_min_search = -45
      } else if (sensorType === 'cameras') {
        sensor.camera_type = 'droneopt1'
        sensor.field_of_view = 360
        sensor.forcedReach = 1000
      } else if (sensorType === 'cannons') {
        sensor.reach = 1500
        sensor.cannon_type = '5B'
      }
      sensorsList.push(sensor)
      sensors[sensorType] = sensorsList
      installation.sensors = sensors
      newPlan.site_plan.installations.push(installation)
      this.plan = { ...newPlan }
      this.orderLayers()
    },
    async orderLayers() {
      await this.$nextTick()
      this.$bus.$emit('bringRfToTop')
      await this.$nextTick()
      this.$bus.$emit('bringCannonToTop')
      await this.$nextTick()
      this.$bus.$emit('bringCameraToTop')
      await this.$nextTick()
      this.$bus.$emit('bringRadarToTop')
      await this.$nextTick()
      this.$bus.$emit('bringDiscovairToTop')
    },
    followCursor: throttle(function(e) {
      if (!this.isDragging) return
      this.$refs.draggingGhostIcon.style.left = `${e.pageX - 20}px`
      this.$refs.draggingGhostIcon.style.top = `${e.pageY - 40}px`
    }, 25),
    availableSpots(sensors) {
      return [0, 1, 2, 3].filter(
        spot =>
          !sensors
            .map(s => (s.direction - sensors[0].direction) / 90)
            .map(v => Math.round(v < 0 ? v + 4 : v))
            .includes(spot)
      )
    },
    nextSpot(sensors, availableSpots, sensor) {
      const currentSpot =
        Math.round(sensor.direction - sensors[0].direction) / 90
      const currentSpotIndex = currentSpot < 0 ? currentSpot + 4 : currentSpot
      return availableSpots.find(v => v > currentSpotIndex) || availableSpots[0]
    }
  },
  watch: {
    dialog(v) {
      this.toggleWizard(v)
      if (v) {
        this.initDialog()
      }
    },
    isDragging(v) {
      if (!v) {
        this.$refs.draggingGhostIcon.style.left = this.$refs.draggingGhostIcon.style.top =
          '-500px'
      }
    }
  },
  computed: {
    ...mapState('plans', ['isDragging', 'status']),
    ...mapGetters('sentries', ['activeSentryId']),
    installations() {
      return get(this.plan, 'site_plan.installations', [])
    },
    sensors() {
      return installationId =>
        get(this.plan, `site_plan.installations[${installationId}].sensors`, [])
    }
  },
  mounted() {
    this.nbSteps = document.querySelectorAll('div.v-stepper__step').length
    this.$bus.$on('wizard/SENSOR_UPDATE', data => {
      const newPlan = cloneDeep(this.plan)
      const installationId = this.installations.findIndex(
        installation => installation.id === data.sensor.sentry_id
      )
      const sensors = this.sensors(installationId)[data.sensor.type]
      const sensorId = sensors.findIndex(sensor => sensor.id === data.sensor.id)
      let newDirection = data.direction

      if (data.shuffle_sector) {
        const emptySectors = this.availableSpots(sensors)
        if (!emptySectors.length) return
        const sectorId = this.nextSpot(sensors, emptySectors, data.sensor)
        newDirection = (sensors[0].direction + sectorId * 90) % 360
      } else {
        const dirSensorTypes = ['rf_sensors', 'radars', 'cannons']
        const otherSensorTypes = dirSensorTypes.filter(
          t => t !== data.sensor.type
        )
        const otherSensors = otherSensorTypes.map(
          t => this.sensors(installationId)[t] || []
        )
        const dDirection = data.direction - sensors[sensorId].direction
        sensors
          .filter(s => s.id != data.sensor.id)
          .forEach(s => (s.direction = (s.direction + dDirection) % 360))
        otherSensors.forEach(t =>
          t.forEach(s => (s.direction = (s.direction + dDirection) % 360))
        )
        otherSensorTypes.forEach(
          (t, i) =>
            otherSensors[i].length &&
            (newPlan.site_plan.installations[installationId].sensors[t] =
              otherSensors[i])
        )
      }
      sensors.splice(sensorId, 1, {
        ...sensors[sensorId],
        direction: newDirection
      })
      newPlan.site_plan.installations[installationId].sensors[
        data.sensor.type
      ] = sensors
      this.plan = { ...newPlan }
      this.orderLayers()
    })
  },
  beforeDestroy() {
    this.$bus.$off('wizard/SENSOR_UPDATE')
  }
}
</script>
<style>
.fadein {
  position: relative;
  animation: fadein 0.5s ease-in-out both;
}
.fadein.delay-05 {
  animation-delay: 0.5s;
}
.fadein.delay-1 {
  animation-delay: 2s;
}
@keyframes fadein {
  from {
    opacity: 0;
    top: -15px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}
.fadein .expand {
  animation: expand 0.5s ease-in-out both;
}
@keyframes expand {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}
#wizardStep1Form .v-text-field__details {
  display: none !important;
}
.wizardMap .draggableSector,
.planMap .draggableSector,
.wizardMap .wizardSector,
.planMap .wizardSector {
  stroke-width: 5;
  opacity: 0.7;
  fill-opacity: 0.4;
}
.wizardMap .wizardSector {
  cursor: grab !important;
}
.wizardMap .wizardSector:active {
  cursor: grabbing !important;
}
.wizardMap .draggableSector:hover {
  opacity: 0.85;
}
.wizardMap .draggableSector:active {
  fill-opacity: 0.5;
}
#wizardStep3Form .theme--dark.v-table,
#wizardStep4Form .theme--dark.v-table {
  background-color: transparent !important;
}
#dragging-ghost-icon {
  animation: swing-icon 1s ease-in-out infinite alternate;
  transform-origin: 50% 100%;
  pointer-events: none;
  display: block;
  width: 40px;
  height: 40px;
  position: fixed;
  top: -500px;
  left: -500px;
  z-index: 999;
}
@keyframes swing-icon {
  0% {
    transform: rotate3d(1, -1, 1, 20deg);
  }
  100% {
    transform: rotate3d(-1, 1, -1, 20deg);
  }
}
#pixel {
  display: block;
  position: absolute;
}
</style>
<style scoped>
.v-dialog {
  overflow: hidden;
}
</style>
